// import { gsap } from "gsap";

// window.addEventListener("scroll", reveal);

// function reveal() {
//   var reveals = document.querySelectorAll(".reveal");

//   for (var i = 0; i < reveals.length; i++) {
//     var windowheight = window.innerHeight;
//     var revealtop = reveals[i].getBoundingClientRect().top;
//     var revealpoint = 150;

//     if (revealtop < windowheight - revealpoint) {
//       reveals[i].classList.add("active");
//     } else {
//       reveals[i].classList.remove("active");
//     }
//   }
// }
// ________

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  for (const job of document.querySelectorAll(".reveal")) {
    let video = job.querySelector("video");
    let tween = gsap.to(job, { opacity: 1, duration: 1 });

    ScrollTrigger.create({
      trigger: job,
      start: "75% bottom",
      end: "top top",
      // markers: true,
      animation: tween,
      onEnter: () => {
        if (video) {
          video.play();
        }
      },
      onEnterBack: () => {
        if (video) {
          video.play();
        }
      },
      onLeave: () => {
        if (video) {
          video.pause();
        }
      },
      onLeaveBack: () => {
        if (video) {
          video.pause();
        }
      },
    });
  }

  // __________

  // _____________
  // Initialiser Isotope sur votre conteneur d'éléments
  //var grid = document.querySelector('.grid');
  var iso = new Isotope(".grid", {
    itemSelector: ".item",
    layoutMode: "fitRows",
    filter: "*",
    // percentPosition: true,
    masonry: {
      // use element for option
      columnWidth: "100%",
    },
  });

  // Appliquer un filtre lorsque l'utilisateur clique sur un bouton
  var buttons = document.querySelectorAll(".filter-button");
  buttons.forEach(function (button) {
    button.addEventListener("click", function (e) {
      var filterValue = e.target.getAttribute("data-filter");
      console.log(filterValue);
      iso.arrange({
        // item element provided as argument
        filter: filterValue,
      });
    });
  });
  //
  var grid = document.querySelector(".grid");

  imagesLoaded(grid).on("progress", function () {
    // layout Isotope after each image loads
    iso.layout();
  });

  iso.on("layoutComplete", function () {
    ScrollTrigger.refresh();
  });

  // buton active
  var openBurger = document.querySelector(".mobile-nav");

  var trigger = document.querySelector(".burger");

  var activatBurger = function () {
    openBurger.classList.add("is-open");
  };

  trigger.addEventListener("click", activatBurger);

  //

  var desactivatBurger = document.querySelector(".mobile-nav");

  var triggerCross = document.querySelector(".cross");

  var removeBurger = function () {
    desactivatBurger.classList.remove("is-open");
  };

  triggerCross.addEventListener("click", removeBurger);

  //
  class PortfolioFilter {
    constructor() {
      this.buttons = document.querySelectorAll(".filter-button");
      this.activeButton = null;
      this.setupEventListeners();
    }

    setupEventListeners() {
      this.buttons.forEach((button) => {
        button.addEventListener("click", () => this.toggleFilter(button));
      });
    }

    toggleFilter(button) {
      if (this.activeButton !== null) {
        this.activeButton.classList.remove("active");
      }

      if (this.activeButton !== button) {
        button.classList.add("active");
        this.activeButton = button;
      } else {
        this.activeButton = null;
      }

      this.filterItems(button.getAttribute("data-filter"));
    }

    filterItems(filter) {
      // Ajoutez ici le code pour filtrer vos éléments en fonction de la valeur de 'filter'
      console.log(`Filtrage avec : ${filter}`);
    }
  }

  // Instanciation de la classe
  const portfolioFilter = new PortfolioFilter();
});
